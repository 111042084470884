.loading-spinner {
  width: 40px;
  height: 40px;
  border: 10px solid #30c0df;
  border-top: 10px solid #2f2e2e;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&family=Poppins&display=swap");

/* scrollbar design */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #6b6868;
}
::-webkit-scrollbar-thumb:hover {
  background: #30c0df;
}
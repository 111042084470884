.sidebar p {
  /* transition: 0.3s; */
  text-decoration: none;
}

.fadeIn-effect {
  animation: fadeIn 2s;
}

.cool-link {
  background: transparent;
  text-decoration: none;
  animation: fadeIn 2s;
  margin-left: 15%;
  margin-bottom: 10px;
  z-index: 1000 !important;
}

.cool-link:hover {
  background: #3f3f46;
  transition: background 500ms linear;
}

.points-div {
  background: url(../../public/assets/bg-points.svg) repeat left top;
  z-index: 0 !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-height: 40rem) {
  .sidebar p {
    font-size: 60px !important;
  }
  .cool-link {
    margin-bottom: 0;
  }
}

@media (max-height: 30rem) {
  .sidebar p {
    font-size: 50px !important;
  }
  .cool-link {
    margin-bottom: 0;
  }
}

@media (max-height: 25rem) {
  .sidebar p {
    font-size: 30px !important;
  }
  .cool-link {
    margin-bottom: 0;
  }
}

@media (max-height: 20rem) {
  .sidebar p {
    font-size: 20px !important;
  }
  .cool-link {
    height: fit-content;
    margin-bottom: 0;
  }
  .cool-links-div {
    margin-top: 40px;
    flex-direction: row !important;
    flex-wrap: wrap;
    overflow-y: auto;
  }
  .points-div {
    display: none;
  }
}

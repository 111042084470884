@keyframes opacityInNavbar {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes opacityOutNavbar {
  0% {
    opacity: 100%;
  }

  100% {
    opacity: 0%;
  }
}

@keyframes opacityIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes opacityOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
@keyframes MoveUpDown {
  0% {
    bottom: -350px;
    opacity: 1;
  }
  100% {
    opacity: 1;
    bottom: 100%;
  }
}

@keyframes MoveLeftRight {
  0% {
    left: -350px;
    opacity: 1;
  }
  100% {
    opacity: 1;
    left: calc(100%);
  }
}

@keyframes MoveLeftRightMobile {
  0% {
    left: -700px;
    opacity: 1;
  }
  100% {
    opacity: 1;
    left: calc(100%);
  }
}


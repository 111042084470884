@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animation-in {
  animation-name: FadeIn;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
